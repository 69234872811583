<template>
  <section class="terms-and-conditions bs-py-4">
    <div class="bs-container">
      <v-card class="bs-shadow-none">
        <div class="pa-6 pa-md-12">
          <TermsConditions />
        </div>
      </v-card>
    </div>
  </section>
</template>

<script>
import TermsConditions from "@/components/TermsConditions.vue";

export default {
  components: {
    TermsConditions,
  },
};
</script>
